.date-time-picker {
  max-width: 436px;
}

.text-box-container {
  position: relative;

  &.disabled {
    input {
      background: rgb(235, 235, 235);
    }
  }

  &.date-picker {
    position: relative;
    $icon-size: 28px;
    display: flex;
    flex-direction: row;
    position: relative;

    .react-datepicker-wrapper {
      position: absolute;
      top: 15px;
      left: 12px;
      height: $icon-size;
      width: $icon-size;
      overflow: hidden;
      color: transparent;

      .date-icon {
        height: $icon-size;
        width: $icon-size;
        display: block;
        outline: none;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        transition: all 0.2s ease-out;
        border-radius: 6px;
        display: block;
        color: #102b35;
        font-weight: 600;

        svg {
          height: 28px;
          width: 28px;
          fill: #102b35;
          transition: all 0.2s ease-out;
        }

        &:hover,
        &:focus {
          svg {
            fill: #625afa;
          }
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    .react-datepicker {
      width: 100%;

      .react-datepicker__month-container {
        width: 100%;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      pointer-events: none;
    }

    input {
      text-align: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .slash {
      font-weight: bold;
      position: absolute;
      bottom: 14px;
      width: 8px;
      text-align: center;
      left: calc(33.3333% + 10px);
      z-index: 1;
    }

    .slash.slash--two {
      left: calc(66.6666% + -5px);
    }

    input.day {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right-width: 0 !important;
      padding-left: 30px !important;
    }

    input.month {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
    }

    input.year {
      border-top-left-radius: 0 !important;
      border-left-width: 0 !important;
      border-bottom-left-radius: 0 !important;
      padding-right: 10px !important;
    }
  }

  &.time-picker {
    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      pointer-events: none;
    }

    display: flex;
    flex-direction: row;
    position: relative;

    input {
      text-align: center;
      position: relative;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    div {
      position: absolute;
      bottom: 15px;
      width: 4px;
      text-align: center;
      left: calc(50% - 2px);
      z-index: 1;
      font-weight: bold;
    }

    input:nth-of-type(1) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right-width: 0 !important;
    }

    input:nth-of-type(2) {
      border-left-width: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
