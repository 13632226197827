.text-box-container {
  position: relative;

  .text-box-buttons {
    display: flex;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    button {
      height: 28px;
      width: auto;
      padding: 0 12px;
      outline: none;
      background: #fff;
      border: 0;
      margin: 0;
      border: 2px solid #ebebeb;
      transition: all 0.2s ease-out;
      border-radius: 6px;
      color: #102b35;
      font-weight: 600;

      .text-box-button-text {
        padding-left: 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        display: block;
      }

      svg {
        height: 16px;
        width: 16px;
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        fill: #102b35;
      }

      &:hover,
      &:focus {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;
        }
      }

      &:disabled {
        background: #fff;
        color: #102b35;
        border-color: #ebebeb;

        svg {
          fill: #102b35;
        }
      }
    }
  }

  .input-error {
    color: rgb(223, 27, 65);
    font-weight: 400;
    padding: 2px 0;
    font-size: 14.88px;
    line-height: 17.112px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  &.text-box-container-has-buttons {
    input,
    textarea {
      padding-right: 45px !important;
    }
  }

  &.has-symbol.left {
    position: relative;

    input {
      padding-left: 22px !important;
    }

    .symbol {
      position: absolute;
      top: 17px;
      left: 12px;
      padding-right: 0;
      background: transparent;
      color: #525f7f;
      font-family: niveau-grotesk, sans-serif;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      font-style: normal;
    }
  }

  .spinner-container {
    position: absolute;
    right: 5px;
    top: 25px;
    left: auto;
    height: 28px;
    width: 28px;

    .spinner {
      border-color: rgb(196, 204, 222);
      height: 28px;
      width: 28px;

      &:after {
        content: '';
        top: -9px;
        width: 15px;
        height: 15px;
        background: #336699;
        border-radius: 10px;
        display: block;
        position: absolute;
        border: 3px solid white;
        z-index: 1;
      }

      &:before {
        z-index: 1;
        top: -8px;
        border: none;
        width: 12px;
        height: 12px;
        background: #336699;
      }
    }
  }
}
