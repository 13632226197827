.checkbox {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include no-select;
  margin: 4px 0;

  h1 {
    color: $block-header-color;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0;
    line-height: 22px;
    font-size: 16px;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 2px;
    padding-left: 0;
  }

  .checkbox__content {
    vertical-align: middle;
  }

  .check {
    width: 52px;
    vertical-align: top;
    // padding-top: 3px;
    cursor: pointer;

    .border {
      box-sizing: content-box;
      display: block;
      position: relative;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      background-color: #fff;
      border: 2px solid #c3cbde;
      transition: 0.2s ease-out all;

      .checkbox-background {
        transition: opacity 0.2s ease-out;
        box-sizing: content-box;
        height: 28px;
        width: 28px;
        top: 2px;
        left: 2px;
        position: absolute;
        border-radius: 3px;
      }

      span {
        box-sizing: content-box;
        position: absolute;
        top: 5px;
        left: 10px;
        height: 12px;
        width: 5px;
        border-right: 3px solid $block-header-color;
        border-bottom: 3px solid $block-header-color;
        transform: rotate(45deg);
        transition: 0.2s ease-out all;
        opacity: 0;
      }
    }
  }

  &.checked {
    .check {
      .border {
        border-color: $block-header-color;
        .checkbox-background {
          span {
            opacity: 1;
          }
        }
      }
    }
  }

  &.full-click {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: #3329f9;

      .check {
        .border {
          outline: none;
          border-color: #3329f9;
          box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.03),
            0px 3px 6px rgba(0, 0, 0, 0.02),
            0 0 0 1px #3329f9;

          span {
            border-color: #3329f9;
          }
        }
      }

      table {
        tr {
          td {
            color: #3329f9;

            .info {
              color: #3329f9;
            }
          }
        }
      }
    }
  }

  .check {
    .border {
      &:hover,
      &:focus,
      &:active {
        outline: none;
        border-color: #3329f9;
        box-shadow:
          0px 1px 1px rgba(0, 0, 0, 0.03),
          0px 3px 6px rgba(0, 0, 0, 0.02),
          0 0 0 1px #3329f9;

        span {
          border-color: #3329f9;
        }
      }
    }
  }
}
