.event-share-message {
  font-size: 14px !important;
  font-weight: 300 !important;
  margin: 8px 0 !important;
}

.event-share {
  button {
    border: 3px solid white !important;
    border-radius: 18px;
    height: 36px;
    width: 36px;
    max-width: 36px;
    position: relative;
    vertical-align: top;
    transition: all 0.2s ease-out;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-right: 1px;

    &:hover,
    &:focus {
      border-color: #625afa !important;
    }

    svg,
    img {
      height: 31.5px;
      width: 31.5px;
      fill: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .scan-button,
  .copy-button {
    outline: none;
    background: black;
    position: relative;

    svg {
      height: 18px;
      width: 18px;
      fill: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .copy-button {
    background: gray;

    &.copy-button-copied {
      background: #55c75a;
    }
  }
}
